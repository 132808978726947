import { IntlProvider } from 'react-intl';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Outlet } from 'react-router';
import { toast, ToastContainer } from 'react-toastify';
import {
  configureAxios,
  configureServices,
  configureSessionReplay,
} from 'config';
import { configureTableCells } from 'envoc-table';
import {
  AxiosInterceptor,
  DateCell,
  DateTimeCell,
  DelayedLoadingToast,
  JsResizeHelper,
  KeepAlive,
  Modal,
  XsrfToken,
} from 'shared/components';
import { FeatureFlagProvider } from 'features/FeatureManagement';
import { PublicIdleTimeout } from 'features/PublicViewing';
import { SessionContext } from 'features/Sessions';
import { CameraContextProvider } from 'features/View';

configureAxios();
configureServices();
configureTableCells({ DateCell, DateTimeCell });
configureSessionReplay();

const queryClient = new QueryClient();

function App() {
  return (
    <IntlProvider locale={'en-us'}>
      <QueryClientProvider client={queryClient}>
        <FeatureFlagProvider>
          <JsResizeHelper />
          <Modal.Provider>
            <SessionContext>
              <CameraContextProvider>
                <Outlet />
                <KeepAlive />
                <PublicIdleTimeout />
              </CameraContextProvider>
            </SessionContext>
          </Modal.Provider>
          <XsrfToken />
          <AxiosInterceptor component={DelayedLoadingToast} />
          <ToastContainer position={toast.POSITION.BOTTOM_CENTER} />
        </FeatureFlagProvider>
      </QueryClientProvider>
    </IntlProvider>
  );
}

export default App;
